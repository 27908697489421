import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  protected constructor(public http: HttpClient) {}

  protected getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      // Add any other headers you need for your API requests
    })
  }

  protected handleError(error: any): Observable<never> {
    console.error(error)
    if(error && error.error) {
      return throwError(error.error)
    }
    else return throwError("An error ocurred. Please try again later")
  }

  protected get<T>(url: string): Observable<T> {
    const headers = this.getHeaders()
    return this.http
      .get<T>(url, { headers })
      .pipe(map((value) => value as T))
      .pipe(catchError(this.handleError))
  }

  protected post<T>(
    url: string,
    data: any,
    customHeaders: any = null,
  ): Observable<T> {
    const headers = customHeaders ? customHeaders : this.getHeaders()
    return this.http
      .post<T>(url, data, { headers })
      .pipe(map((value) => value as T))
      .pipe(catchError(this.handleError))
  }

  protected put<T>(url: string, data: any): Observable<T> {
    const headers = this.getHeaders()
    return this.http
      .put<T>(url, data, { headers })
      .pipe(map((value) => value as T))
      .pipe(catchError(this.handleError))
  }

  protected patch<T>(url: string, data: any): Observable<T> {
    const headers = this.getHeaders()
    return this.http
      .patch<T>(url, data, { headers })
      .pipe(map((value) => value as T))
      .pipe(catchError(this.handleError))
  }

  protected delete<T>(url: string): Observable<boolean> {
    const headers = this.getHeaders()
    return this.http
      .delete<boolean>(url, { headers })
      .pipe(catchError(this.handleError))
  }
}
