export const environment = {
  production: false,
  api: {
    baseUrl: 'https://due-diligence-san-api-stage.azurewebsites.net/api/v1',
    countries: 'regions',
    businesses: 'businesses',
    stakeholders: 'stakeholders',
    interlocutors: 'contact-persons',
    risks: 'risks',
    risksSelected: 'risks-selected',
    controls: 'controls',
    controlType: 'controltypes',
    companies: 'companies',
    projects: 'projects',
    areas: 'areas',
    users: 'users',
    topics: 'topics',
    categories: 'categories',
    projectOwner: 'project-owners',
    controlsEffectiveness: 'controls-effectiveness',
  },
  esrsCategory: 'DD8C230A-3D73-45FD-9DF1-E10E40D5A837',
  smCategory: '414ED4CC-8002-4032-B3B2-20942F124863',
  esrsTopics: [
    {
      name: 'ESRS S1',
      id: 'B9BDA360-1170-48A6-8BD9-3259C1B9141E',
    },
    {
      name: 'ESRS S2',
      id: '675DDF41-18B7-46A8-BC9C-AC4A6474A6D8',
    },
    {
      name: 'ESRS S3',
      id: 'C66F56C2-CAFE-40D4-9C39-0992575C6C8F',
    },
    {
      name: 'ESRS S4',
      id: '8A318732-5B5E-48C4-A47A-29DBCAE4F131',
    },
    {
      name: 'ESRS G1',
      id: 'be8ac897-dcf0-4824-80d0-b9ac6b023b99',
    },
  ],
  availablePageSizes: [5, 10, 20, 50, 100],
  auth: {
    issuer: 'https://valora-idp.azurewebsites.net/realms/duediligence-san-stage',
    clientId: 'due-diligence-web',
    tokenEndpoint:
      'https://valora-idp.azurewebsites.net/realms/duediligence-san-stage/protocol/openid-connect/token',
    userInfoEndpoint:
      'https://valora-idp.azurewebsites.net/realms/duediligence-san-stage/protocol/openid-connect/userinfo',
    requireHttps: false,
    allowedUrls: [
      'https://due-diligence-san-api-stage.azurewebsites.net',
      'http://due-diligence-san-api-stage.azurewebsites.net',
      'https://localhost:7036/api/v1',
    ],
  },
}
