import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoginComponent } from './pages/login/login.component'
import { AuthGuard } from './shared/guards/auth-guard'
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component'
import { RecoverPasswordTokenComponent } from './pages/recover-password-token/recover-password-token.component'

const routes: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'reset-password-update',
    component: RecoverPasswordTokenComponent,
    pathMatch: 'full',
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./pages/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/main/main.module').then((m) => m.MainModule),
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '/home/not-found', pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
