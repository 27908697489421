import { Injectable } from '@angular/core'
import { MessageService } from 'primeng/api'

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  showToast(
    severity: 'success' | 'info' | 'warn' | 'error',
    summary: 'Success' | 'Info' | 'Warning' | 'Error',
    detail: string,
  ) {
    this.messageService.add({
      severity,
      summary,
      detail,
    })
  }
}
