<div class="content">
  <div class="gradient-mid"></div>
  <div class="content-login">
    <div class="form-login">
      <div class="content-form p-4">
        <form [formGroup]="form" (submit)="login()">
          <div class="grid w-full">
            <div class="col-12">
              <div class="flex flex-column">
                <span class="p-float-label">
                  <input
                    pInputText
                    formControlName="email"
                    id="email"
                    type="email"
                    required="required"
                  />
                  <label htmlFor="email">Email</label>
                </span>
              </div>
              <div
                class="text-xs text-red-500 mt-1"
                *ngIf="form.get('email')?.invalid && form.get('email')?.touched"
              >
                <div *ngIf="form.get('email')?.errors.required">
                  The field is required.
                </div>
                <div *ngIf="form.get('email')?.errors.email">
                  The field must contain a valid email.
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="flex flex-column">
                <span class="p-float-label">
                  <input
                    pInputText
                    formControlName="password"
                    id="password"
                    type="password"
                    required="required"
                  />
                  <label htmlFor="password">Password</label>
                </span>
              </div>
              <div
                class="text-xs text-red-500 mt-1"
                *ngIf="
                  form.get('password')?.invalid && form.get('password')?.touched
                "
              >
                <div *ngIf="form.get('password')?.errors.required">
                  The field is required.
                </div>
              </div>
            </div>
            <div class="col-12">
              <p-button
                type="submit"
                class="p-button-raised standard-button-within-form mt-0 w-full"
                [disabled]="!form?.valid"
              >
                <span *ngIf="isLoading" class="text-center w-full"
                  >wait...</span
                >
                <i *ngIf="isLoading" class="pi pi-spin pi-spinner"></i>
                <span *ngIf="!isLoading" class="text-center w-full">Login</span>
              </p-button>
            </div>
          </div>
        </form>

        <div class="mt-5 text-center text-gray">
          Forgot your password?
          <a routerLink="/recover-password"><u>Click here</u></a>
        </div>
      </div>
    </div>
  </div>
</div>
