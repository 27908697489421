<div class="content">
  <div class="gradient-mid"></div>
  <div class="content-login">
    <div class="form-login">
      <div class="content-form p-4">
        <form [formGroup]="formChangePassword" (ngSubmit)="recoverPassword()">
          <div class="grid w-full">
            <div class="col-12">
              <div class="flex flex-column">
                <span class="p-float-label">
                  <input
                    pInputText
                    formControlName="email"
                    id="email"
                    type="email"
                    required="required"
                  />
                  <label htmlFor="email">Email</label>
                </span>
              </div>
              <div
                class="text-xs text-red-500 mt-1"
                *ngIf="
                  formChangePassword.get('email')?.invalid &&
                  formChangePassword.get('email')?.touched
                "
              >
                <div *ngIf="formChangePassword.get('email')?.errors.email">
                  It must be a valid email
                </div>
                <div *ngIf="formChangePassword.get('email')?.errors.required">
                  The field is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <p-button
              type="submit"
              class="p-button-raised standard-button-within-form mt-0 w-full"
              [disabled]="!formChangePassword?.valid"
            >
              <span *ngIf="formChangePasswordSending" class="text-center w-full"
                >wait...</span
              >
              <i
                *ngIf="formChangePasswordSending"
                class="pi pi-spin pi-spinner"
              ></i>
              <span
                *ngIf="!formChangePasswordSending"
                class="text-center w-full"
                >Recover password</span
              >
            </p-button>
          </div>
        </form>
      </div>
      <div class="mt-5 text-center text-gray">
        Would you like to log in?
        <a routerLink="/login"><u>Click here</u></a>
      </div>
    </div>
  </div>
</div>
