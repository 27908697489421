import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/shared/services/idp/auth/auth.service'
import { ToastService } from 'src/app/shared/services/toast/toast.service'
import { ActivatedRoute, Router } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AccountService } from 'src/app/shared/services/api/account.service'

@Component({
  selector: 'app-recover-password-token',
  templateUrl: './recover-password-token.component.html',
  styleUrls: ['./recover-password-token.component.scss'],
})
export class RecoverPasswordTokenComponent implements OnInit {
  formChangePassword: FormGroup
  formChangePasswordSending = false
  success = false

  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.accountService
      .tokenPasswordIsValid(this.route.snapshot.queryParamMap.get('token'))
      .subscribe(
        () => {
          // ok
        },
        () => {
          this.router.navigateByUrl('/login')
        },
      )

    this.formChangePassword = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(50),
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(50),
          ],
        ],
      },
      {
        validator: this.passwordMatchValidator,
      },
    )
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const passwordControl = formGroup.get('password')
    const confirmPasswordControl = formGroup.get('confirmPassword')

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true })
    } else {
      confirmPasswordControl.setErrors(null)
    }
  }

  changePassword() {
    this.formChangePasswordSending = true
    this.accountService
      .recoverUpdatePassword(
        this.formChangePassword.value.password,
        this.route.snapshot.queryParamMap.get('token'),
      )
      .subscribe(() => {
        this.success = true
        this.formChangePasswordSending = false
        this.toastService.showToast(
          'success',
          `Success`,
          `Contraseña cambiada correctamente.`,
        )
      })
  }
}
