import { inject, Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { OAuthService } from 'angular-oauth2-oidc'
import { Observable } from 'rxjs'
import { AuthService } from '../services/idp/auth/auth.service'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.authService.hasValidAccessToken()) {
      this.router.navigateByUrl('/login')
      return false
    }
    return true
  }
}
