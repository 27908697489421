import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs'
import { ApiService } from './api-service.service'

@Injectable({
  providedIn: 'root',
})
export class AccountService extends ApiService {
  url = environment.api.baseUrl

  sendMailRecoverPassword(username: string): Observable<string> {
    return this.post<string>(`${this.url}/account/forgot-password`, {
      username: username,
    })
  }

  recoverUpdatePassword(newPassword: string, token: string) {
    return this.post<string>(`${this.url}/account/forgot-password-update`, {
      token: token,
      password: newPassword,
    })
  }

  tokenPasswordIsValid(token: string) {
    return this.get<string>(
      `${this.url}/account/forgot-password-update?token=${token}`,
    )
  }

  updatePassword(newpassword: string): Observable<void> {
    return this.put<void>(`${this.url}/account/me/password`, {
      password: newpassword,
    })
  }

  updateUserData(payload: {
    userName: string
    firstName: string
    lastName: string
  }): Observable<void> {
    return this.put<void>(`${this.url}/account/me`, payload)
  }

  getUserData(): Observable<{
    userName: string
    firstName: string
    lastName: string
  }> {
    return this.get<{
      userName: string
      firstName: string
      lastName: string
    }>(`${this.url}/account/me`)
  }
}
