import { Component, OnInit } from '@angular/core'
import { ToastService } from 'src/app/shared/services/toast/toast.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AccountService } from 'src/app/shared/services/api/account.service'

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
})
export class RecoverPasswordComponent implements OnInit {
  formChangePassword: FormGroup
  formChangePasswordSending = false

  constructor(
    private accountService: AccountService,
    private readonly toastService: ToastService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.formChangePassword = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }

  async recoverPassword(): Promise<void> {
    this.formChangePasswordSending = true

    this.accountService
      .sendMailRecoverPassword(this.formChangePassword.value.email)
      .subscribe(
        () => {
          this.formChangePasswordSending = false
          this.toastService.showToast(
            'success',
            `Success`,
            `Se ha enviado el mail de recuperación de contraseña.`,
          )
          this.formChangePassword.reset()
          this.formChangePassword.patchValue({
            email: '',
          })
        },
        () => {
          this.formChangePasswordSending = false
          this.toastService.showToast('error', `Error`, `El usuario no existe.`)
        },
      )
  }
}
