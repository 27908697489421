<div>
  <div class="flex flex-row align-items-center justify-content-center">
    <img src="../../../../assets/triangle_warning.svg"
         alt="warning"
    />
    <p class="ml-3">{{ warningMessage }}</p>
  </div>
  <div class="flex flex-row align-items-start justify-content-start mb-6">
    <div style="width: 24px;"></div>
    <p class="ml-3"><strong>Are you sure</strong> you want to submit the form?</p>
  </div>

  <div class="flex flex-row align-items-start justify-content-between">
    <p-button
      class="mr-2 my-2"
      styleClass="p-button-secondary p-button-lg px-6 border-radius-12"
      label="Yes, Send Form"
      (click)="send()"
    >
    </p-button>

    <p-button
      label="Cancel, Review"
      class="mr-2 my-2"
      styleClass="p-button-secondary p-button-outlined p-button-lg px-6 border-radius-12"
      (click)="cancel()"
    >
    </p-button>
  </div>
</div>
