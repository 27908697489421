import { Injectable, isDevMode } from '@angular/core'
import { Router } from '@angular/router'
import { AuthConfig, OAuthService, TokenResponse } from 'angular-oauth2-oidc'
import { filter } from 'rxjs'
import { HttpHeaders } from '@angular/common/http'
import { UserProfile } from 'src/app/shared/domain/user-profile'
import { environment } from 'src/environments/environment'

export interface IProfileInfo {
  info: UserProfile
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly router: Router,
    private readonly oauthService: OAuthService,
  ) {
    this.configureAuth()
    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_received'))
      .subscribe((data) => {
        console.log(data)
        this.router.navigate(['/'])
      })
    // TODO - remove not needed , Guard manage this part
    /*
    if (!this.oauthService.hasValidAccessToken()) {
      console.log('redirect to login');
      //   this.router.navigate(['login']);
    }
  */
  }

  public async login(user: string, password: string): Promise<TokenResponse> {
    return this.oauthService.fetchTokenUsingPasswordFlow(
      user,
      password,
      new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    )
  }

  public logout(): void {
    return this.oauthService.logOut({ client_id : environment.auth.clientId });
  }

  public loadUserProfile(): Promise<IProfileInfo> {
    return this.oauthService.loadUserProfile() as Promise<IProfileInfo>
  }

  public hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken()
  }

  public refreshToken() {
    return this.oauthService.refreshToken()
  }

  private configureAuth() {
    this.oauthService.configure(this.getAuthConfig())
    this.oauthService.setStorage(localStorage)
    this.oauthService.loadDiscoveryDocumentAndTryLogin()
    this.oauthService.setupAutomaticSilentRefresh()
  }

  private getAuthConfig(): AuthConfig {
    const authCodeFlowConfig: AuthConfig = {
      issuer: environment.auth.issuer,
      redirectUri: window.location.origin + '/',
      clientId: environment.auth.clientId,
      responseType: 'code',
      scope: 'openid profile offline_access',
      showDebugInformation: isDevMode(),
      loginUrl: window.location.origin + '/signin',
      dummyClientSecret: 'abc',
      tokenEndpoint: environment.auth.tokenEndpoint,
      silentRefreshRedirectUri:
        window.location.origin + '/assets/silent-refresh.html',
      silentRefreshShowIFrame: false,
      userinfoEndpoint: environment.auth.userInfoEndpoint,
      useSilentRefresh: true,
      requireHttps: environment.auth.requireHttps,
      oidc: false,
    }
    return authCodeFlowConfig
  }
}
