import { NgModule } from '@angular/core'
import { ButtonModule } from 'primeng/button'
import { InputTextModule } from 'primeng/inputtext'
import { DropdownModule } from 'primeng/dropdown'
import { ListboxModule } from 'primeng/listbox'
import { DividerModule } from 'primeng/divider'
import { TableModule } from 'primeng/table'
import { ToastModule } from 'primeng/toast'
import { DynamicDialogModule } from 'primeng/dynamicdialog'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { MenuModule } from 'primeng/menu'
import { AvatarModule } from 'primeng/avatar'
import { ImageModule } from 'primeng/image'
import { FileUploadModule } from 'primeng/fileupload'
import { CalendarModule } from 'primeng/calendar'
import { MultiSelectModule } from 'primeng/multiselect'
import { SidebarModule } from 'primeng/sidebar'
import { ConfirmPopupModule } from 'primeng/confirmpopup'
import { InputSwitchModule } from 'primeng/inputswitch'
import { SkeletonModule } from 'primeng/skeleton'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { InputNumberModule } from 'primeng/inputnumber'
import { ChipModule } from 'primeng/chip'
import { ChipsModule } from 'primeng/chips'
import { CheckboxModule } from 'primeng/checkbox'
import { AccordionModule } from 'primeng/accordion'
import { TabViewModule } from 'primeng/tabview'
import { BadgeModule } from 'primeng/badge'
import { DialogModule } from 'primeng/dialog'
import { TooltipModule } from 'primeng/tooltip'
import { SelectButtonModule } from 'primeng/selectbutton'
import { MessagesModule } from 'primeng/messages'
import { StyleClassModule } from 'primeng/styleclass'
import { StepsModule } from 'primeng/steps'
import { RadioButtonModule } from 'primeng/radiobutton'

@NgModule({
  declarations: [],
  imports: [
    ButtonModule,
    InputTextModule,
    DropdownModule,
    ListboxModule,
    DividerModule,
    ButtonModule,
    MenuModule,
    TableModule,
    ToastModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    AvatarModule,
    ImageModule,
    FileUploadModule,
    CalendarModule,
    ButtonModule,
    MultiSelectModule,
    InputTextModule,
    SidebarModule,
    ConfirmPopupModule,
    InputSwitchModule,
    SkeletonModule,
    InputTextareaModule,
    InputNumberModule,
    ChipModule,
    ChipsModule,
    CheckboxModule,
    AccordionModule,
    TabViewModule,
    BadgeModule,
    DialogModule,
    TooltipModule,
    SelectButtonModule,
    MessagesModule,
    StyleClassModule,
    StepsModule,
    RadioButtonModule,
  ],
  exports: [
    ButtonModule,
    InputTextModule,
    DropdownModule,
    ListboxModule,
    DividerModule,
    ButtonModule,
    MenuModule,
    TableModule,
    ToastModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    AvatarModule,
    ImageModule,
    FileUploadModule,
    CalendarModule,
    ButtonModule,
    MultiSelectModule,
    InputTextModule,
    SidebarModule,
    ConfirmPopupModule,
    InputSwitchModule,
    SkeletonModule,
    ChipModule,
    ChipsModule,
    CheckboxModule,
    AccordionModule,
    TabViewModule,
    BadgeModule,
    DialogModule,
    TooltipModule,
    SelectButtonModule,
    MessagesModule,
    StyleClassModule,
    StepsModule,
    RadioButtonModule,
  ],
})
export class PrimeNgModule {}
