import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-public-measure-feedback-confirm-warning',
  templateUrl: './public-measure-feedback-confirm-warning.component.html',
  styleUrls: ['./public-measure-feedback-confirm-warning.component.scss']
})
export class PublicMeasureFeedbackConfirmWarningComponent implements OnInit{
  public warningMessage: string = "";

  constructor( private ref: DynamicDialogRef,
               private config: DynamicDialogConfig,) {
  }

  public ngOnInit(): void {
    if (this.config?.data?.warningMessage) {
      this.warningMessage = this.config?.data?.warningMessage;
      console.log("warning-message:", this.warningMessage);
    }
  }

  send() {
    this.ref.close({ send: true })
  }

  cancel() {
    this.ref.close()
  }

}
