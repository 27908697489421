<div class="content">
  <div class="gradient-mid"></div>
  <div class="content-login">
    <div class="form-login">
      <div class="content-form">
        <div *ngIf="success" class="p-4">
          The password has been changed successfully, you can log in from
          <a routerLink="/login">here.</a>
        </div>
        <form
          [formGroup]="formChangePassword"
          (ngSubmit)="changePassword()"
          *ngIf="!success"
        >
          <div class="gap-4">
            <div>
              <div class="flex flex-column gap-2 mt-5">
                <h1>Password</h1>
                <input
                  pInputText
                  formControlName="password"
                  id="password"
                  type="password"
                  required="required"
                />
              </div>
              <div
                class="text-xs text-red-500 mt-1"
                *ngIf="
                  formChangePassword.get('password')?.invalid &&
                  formChangePassword.get('password')?.touched
                "
              >
                <div
                  *ngIf="formChangePassword.get('password')?.errors.required"
                >
                  The field is required.
                </div>
                <div
                  *ngIf="formChangePassword.get('password')?.errors.minlength"
                >
                  The field must be at least 8 characters.
                </div>
                <div
                  *ngIf="formChangePassword.get('password')?.errors.maxlength"
                >
                  The field must not exceed 50 characters.
                </div>
              </div>
            </div>
            <div>
              <div class="flex flex-column gap-2 mt-5">
                <h1>Confirm Password</h1>
                <input
                  pInputText
                  formControlName="confirmPassword"
                  id="confirmPassword"
                  type="password"
                  required="required"
                />
              </div>
              <div
                class="text-xs text-red-500 mt-1"
                *ngIf="
                  formChangePassword.get('confirmPassword')?.invalid &&
                  formChangePassword.get('confirmPassword')?.touched
                "
              >
                <div
                  *ngIf="
                    formChangePassword.get('confirmPassword')?.errors.required
                  "
                >
                  The field is required.
                </div>
                <div
                  *ngIf="
                    formChangePassword.get('confirmPassword')?.errors.minlength
                  "
                >
                  The field must be at least 8 characters.
                </div>
                <div
                  *ngIf="
                    formChangePassword.get('confirmPassword')?.errors.maxlength
                  "
                >
                  The field must not exceed 50 characters.
                </div>
                <div
                  *ngIf="
                    formChangePassword
                      .get('confirmPassword')
                      .hasError('passwordMismatch')
                  "
                >
                  Passwords do not match.
                </div>
              </div>
            </div>
          </div>

          <div class="m-auto text-left mt-5">
            <p-button
              type="submit"
              [disabled]="!formChangePassword?.valid"
              styleClass="p-button m-auto"
            >
              <span *ngIf="formChangePasswordSending">wait...</span>
              <i
                *ngIf="formChangePasswordSending"
                class="pi pi-spin pi-spinner"
              ></i>
              <span *ngIf="!formChangePasswordSending">Save</span>
            </p-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
