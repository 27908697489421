import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { PrimeNgModule } from './shared/prime-ng.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgChartsModule } from 'ng2-charts'
import { OAuthModule } from 'angular-oauth2-oidc'
import { environment } from '../environments/environment'
import { MessageService } from 'primeng/api'
import { ToastService } from './shared/services/toast/toast.service'
import { HttpRequestInterceptor } from './shared/interceptors/http-request-interceptor'
import { ErrorHandlerInterceptor } from './shared/interceptors/error-handler.interceptor.'
import { LoginComponent } from './pages/login/login.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MapComponent } from './components/map/map.component'
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component'
import { RecoverPasswordTokenComponent } from './pages/recover-password-token/recover-password-token.component'
import { FormatDateModule } from './pipes/format-date-module';
import { PublicMeasureFeedbackConfirmWarningComponent } from './components/public/public-measure-feedback-confirm-warning/public-measure-feedback-confirm-warning.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RecoverPasswordComponent,
    RecoverPasswordTokenComponent,
    PublicMeasureFeedbackConfirmWarningComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    PrimeNgModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgChartsModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
        allowedUrls: environment.auth.allowedUrls,
      },
    }),
    FormatDateModule,
  ],
  providers: [
    MessageService,
    ToastService,
    /*{
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
        },*/
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
