import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/shared/services/idp/auth/auth.service'
import { ToastService } from 'src/app/shared/services/toast/toast.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isLoading = false
  form: FormGroup

  constructor(
    private readonly authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    if (this.authService.hasValidAccessToken()) {
      this.router.navigateByUrl('/companies')
    }

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    })
  }

  async login(): Promise<void> {
    try {
      this.isLoading = true
      await this.authService.login(
        this.form.value.email,
        this.form.value.password,
      )
    } catch (error: unknown) {
      this.toastService.showToast(
        'error',
        `Error`,
        `Wrong email or password, try again`,
      )
      this.isLoading = false
    }
  }
}
